import { TakeoverAd } from 'js/components/specific/top/TakeoverAd/type';
import { EcnFrames } from 'js/const/ecnFrames';

export const TAKEOVER_AD_MAP: Record<string, TakeoverAd> = {
  // ループで処理できないため、1~4件目のみ有効とする
  qoo10_second_202411: {
    linkUrl: `/campaign/202411qoo10/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Qoo10 メガ割',
    headerBannerUrl: '/top/img/takeover/qoo10_2411_header.png',
    firstSideBannerUrl: '/top/img/takeover/qoo10_2411_side1.png',
    secondSideBannerUrl: '/top/img/takeover/qoo10_2411_side2.png',
    background:
      'linear-gradient(rgb(255 178 181), rgb(255 108 113) 3000px, rgb(222 44 86) 4000px)',
  },
  qoo10_first_202411: {
    linkUrl: `/campaign/202411qoo10/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Qoo10 メガ割',
    headerBannerUrl: '/top/img/takeover/qoo10_2411_header.png',
    firstSideBannerUrl: '/top/img/takeover/qoo10_2411_side1.png',
    secondSideBannerUrl: '/top/img/takeover/qoo10_2411_side2.png',
    background:
      'linear-gradient(rgb(255 178 181), rgb(255 108 113) 3000px, rgb(222 44 86) 4000px)',
  },
  temu_202411: {
    linkUrl: `/ad/10709703/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Temu',
    headerBannerUrl: '/top/img/takeover/temu_202406_header.png',
    firstSideBannerUrl: '/top/img/takeover/temu_202406_side1.png',
    secondSideBannerUrl: '/top/img/takeover/temu_202406_side2.png',
    background: 'linear-gradient(rgb(255 140 41), rgb(255 201 0) 3000px)',
  },
  olive_202410: {
    linkUrl: `/ad/10714247/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: '三井住友銀行 Olive',
    headerBannerUrl: '/top/img/takeover/olive_202410_header.png',
    firstSideBannerUrl: '/top/img/takeover/olive_202410_side1.png',
    secondSideBannerUrl: '/top/img/takeover/olive_202410_side2.png',
    background: '#c5d700',
  },
};
